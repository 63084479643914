@font-face {
    font-family: "regular";
    src: url(../fonts/Gotham-Book-Regular.otf) format("opentype");
}

@font-face {
    font-family: "heading";
    src: url(../fonts/fairweather.woff2) format("woff2");
}

@font-face {
    font-family: "sub-heading";
    src: url(../fonts/Gotham-Light.woff2) format("woff2");
}

body,
html,
#root {
    font-family: regular;
    font-size: 11pt;
    font-weight: bold;

    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overscroll-behavior-y: none;
    position: fixed;
    overflow: hidden;
    background-color: #ebecec;
}
.green {
    color: #333333;
}
.yellow {
    color: #de5c25;
}
p {
    line-height: 1.75;
    font-family: regular;
    font-size: 11pt;
}

h1 {
    font-family: heading;
    text-transform: uppercase;
    font-size: 28pt;
    line-height: 1.1;
}

h3 {
    font-family: sub-heading;
    text-transform: uppercase;
    font-size: 15pt;
    font-weight: 300;
    line-height: 1.1;
}

.main-large {
    padding-left: 40px;
    padding-top: 8px;
    padding-right: 20px;
    margin-left: 0;

    width: calc(100vw - 250px - 40px - 20px);
    max-width: calc(100vw - 250px);
}

main {
    overflow-y: auto;
    height: calc(100% - 40px);
    padding-bottom: 30px;
}

.main-small {
    padding-top: 8px;
    padding-right: 20px;
    margin-left: 0px;

    width: calc(100vw - 40px - 20px - 20px);
    padding-left: 20px;
}

.main-list-scroll {
    height: calc(100vh - 170px) !important;
    overflow: auto !important;
    width: 100%;
}

.only-print {
    display: none;
}

@media print {
    body,
    html,
    #root {
        padding: 0;
        margin: 0;
        width: 100%;
        height: auto;
        position: static;
        overflow: auto;
    }
    .no-print {
        display: none;
    }

    .only-print {
        display: block;
    }

    * {
        overflow-y: visible;
    }
}

table {
    position: relative;
    border-collapse: collapse;
    margin-bottom: 30px;
    overflow: hidden;
}
table tfoot {
    display: table-footer-group;
}
#print-area {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    padding: auto;
}

th,
td {
    page-break-inside: avoid;
    text-align: left;
    padding: 12px 15px;
    vertical-align: top;
    border: 0px solid black;
}
tr {
    border-top: 1px solid lightgray;
    vertical-align: top;
}
thead {
    display: table-header-group;
}

tfoot {
    display: table-footer-group;
}

@page {
    size: ledger landscape;
    margin: 15mm 15mm 15mm 15mm;
}

.fullPage {
    height: 100vh;
}
